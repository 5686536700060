import { Address, CreateAddress } from "./addressByPostCode";

// Request
export interface CreateRequest {
  permissions?: Permission[] | null;
  name: string;
  email: string;
}

export interface UpdateRequest {
  name?: string;
  permissions?: Permission[] | null;
}

export interface UpdateProfileRequest {
  email?: string;
  name?: string;
  phoneNumber?: string;
  cpf?: string;
  birthdate?: Date | string;
  motherName?: string;
  monthlyIncome?: number;
  professionalOccupation?: string;
}

export interface UpdatePasswordRequest {
  currentPassword: string;
  password: string;
}

// Response
export enum UserRole {
  RESTAURANT_USER = "RESTAURANT_USER",
  ADMINISTRATOR = "ADMINISTRATOR",
  USER = "USER",
}

export const UserRoleTranslated = {
  [UserRole.RESTAURANT_USER]: "Restaurante",
  [UserRole.ADMINISTRATOR]: "Administrador",
  [UserRole.USER]: "Cliente",
};

export enum Permission {
  RESTAURANTS = "RESTAURANTS",
  CUSTOMERS = "CUSTOMERS",
  FINANCIAL = "FINANCIAL",
  CATEGORIES = "CATEGORIES",
  ORDERS = "ORDERS",
  MENU = "MENU",
  RESTAURANT = "RESTAURANT",
  REVIEWS = "REVIEWS",
  USERS = "USERS",
  IMAGE_BANK = "IMAGE_BANK",
  COMPLAINTS = "COMPLAINTS",
}

export const AdministratorPermissions = [
  Permission.RESTAURANTS,
  Permission.CUSTOMERS,
  Permission.FINANCIAL,
  Permission.CATEGORIES,
  Permission.IMAGE_BANK,
  Permission.COMPLAINTS,
  Permission.USERS,
];

export const RestaurantUserPermissions = [
  Permission.ORDERS,
  Permission.MENU,
  Permission.RESTAURANT,
  Permission.IMAGE_BANK,
  Permission.REVIEWS,
  Permission.USERS,
  Permission.FINANCIAL,
  Permission.COMPLAINTS,
];

export const PermissionTranslated = {
  [Permission.RESTAURANTS]: "Restaurantes",
  [Permission.CUSTOMERS]: "Clientes",
  [Permission.FINANCIAL]: "Financeiro",
  [Permission.CATEGORIES]: "Categorias",
  [Permission.ORDERS]: "Pedidos",
  [Permission.MENU]: "Cardápio",
  [Permission.RESTAURANT]: "Restaurante",
  [Permission.REVIEWS]: "Avaliações",
  [Permission.IMAGE_BANK]: "Imagens",
  [Permission.USERS]: "Usuários",
  [Permission.COMPLAINTS]: "Contestações",
};

export enum UserStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
}

export interface User {
  id: string;
  role: UserRole;
  roleTranslated: string;
  permissions: Permission[] | null;
  permissionsTranslated: string[] | null;
  status: UserStatus;
  name: string;
  email: string;
  phoneNumber: string;
  cpf: string;
  birthdate: Date | string;
  motherName?: string | null;
  monthlyIncome: number;
  professionalOccupation: string;
  address: Address | null;
  isRestaurantManager: boolean;
  restaurantId?: string;
  complaintCount: number;
}

export interface CreateRestaurantUser {
  name: string;
  email: string;
  confirmEmail?: string;
  phoneNumber: string;
  cpf: string;
  birthdate: Date | string;
  motherName?: string | null;
  monthlyIncome: number;
  professionalOccupation: string;
  address: CreateAddress;
}
